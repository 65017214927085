import { FC, HTMLProps } from 'react';

type OptionValue = {
  key: number;
  value: string;
  label: string;
};

type PropTypes = {
  label: string;
  required?: boolean;
  valid?: boolean;
  dirty?: boolean;
  value?: string;
  answers: OptionValue[];
} & HTMLProps<HTMLInputElement>;

const MMDRadio: FC<PropTypes> = ({
  label,
  required = false,
  valid = true,
  dirty = true,
  answers = [],
  value,
  ...rest
}) => (
  <div className="control">
    <label className={`label ${required ? 'required' : ''}`}>{label}</label>

    {answers.map(({ key, label, value: constantValue }) => (
      <div key={key}>
        <label key={key} className="radio mr-2">
          {label}
        </label>

        <input
          className="mr-2"
          type="radio"
          value={constantValue.toLowerCase()}
          checked={(value || '').toLowerCase() === constantValue.toLowerCase()}
          {...rest}
        />
      </div>
    ))}

    {!valid && <span className="has-text-danger">Select one</span>}
  </div>
);

export default MMDRadio;

import { createBrowserHistory } from 'history';
import {
  providersReducer as providers,
  providersSagas,
  ProvidersState,
} from 'modules/providers.module';
import {
  specialitiesReducer as specialities,
  specialitiesSagas,
  SpecialitiesState,
} from 'modules/specialities.module';
import {
  credentialsReducer as credentials,
  credentialsSagas,
  CredentialsState,
} from 'modules/credentials.module';
import {
  templatesReducer as templates,
  templatesSagas,
  TemplatesState,
} from 'modules/templates.module';
import {
  transactionsReducer as transactions,
  transactionsSagas,
  TransactionsState,
} from 'modules/transactions.module';
import {
  bookingsReducer as bookings,
  bookingsSagas,
  BookingsState,
} from 'modules/bookings.module';
import {
  bookingsPublicReducer as bookingsPublic,
  bookingsPublicSagas,
  BookingsPublicState,
} from 'modules/bookings-public.module';
import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';

import createSagaMiddleware, { SagaIterator } from 'redux-saga';
import { notificationFactory } from 'utils/onRoute';
import { all } from 'redux-saga/effects';
import {
  adminToolsReducer as adminTools,
  adminToolsSagas,
  AdminToolsState,
} from './modules/adminTools.module';
import {
  authReducer as auth,
  authSagas,
  AuthState,
} from './modules/auth.module';
import {
  formReducer as forms,
  formSagas,
  FormState,
} from './modules/forms.module';
import {
  patientsReducer as patients,
  patientsSagas,
  PatientsState,
} from './modules/patients.module';
import { childSagas } from './modules/patients/children.module';
import { petSagas } from './modules/patients/pets.module';
import { forkList } from './utils/forkList';
import { ENTITIES } from './utils/constants';
import {
  notificationsReducer as notifications,
  NotificationsState,
  notificationsSagas,
} from 'modules/notifications.module';
import {
  promoCodeReducer as promoCodes,
  promoCodeSagas,
  PromoCodesState,
} from 'modules/promo-code.module';
import {
  pharmacyReducer as pharmacies,
  pharmacySagas,
  PharmaciesState,
} from 'modules/pharmacies.module';
import {
  medicationReducer as medications,
  medicationsSagas,
  MedicationsState,
} from 'modules/medication.module';
import {
  affiliationReducer as affiliations,
  affiliationSagas,
  AffiliationsState,
} from 'modules/affiliations.module';
import {
  organizationsReducer as organizations,
  organizationsSagas,
  OrganizationsState,
} from 'modules/organizations.module';
import {
  organizationTypesReducer as organizationTypes,
  organizationTypesSagas,
  OrganizationTypesState,
} from 'modules/organization-types.module';
import {
  allergyReducer as allergies,
  allergySagas,
  AllergiesState,
} from 'modules/allergies.module';
import {
  conditionReducer as conditions,
  conditionSagas,
  ConditionsState,
} from 'modules/conditions.module';
import {
  splitPercentageReducer as splitPercentage,
  splitPercentageSagas,
  SplitPercentageState,
} from 'modules/split-percentage.module';
import {
  termAndConditionReducer as termsAndConditions,
  termAndConditionSagas,
  TermsAndConditionsState,
} from 'modules/terms-and-conditions.module';
import {
  privacyPolicyReducer as privacyPolicies,
  privacyPolicySagas,
  PrivacyPoliciesState,
} from 'modules/privacy-policy.module';
import {
  patientConsentSagas,
  PatientConsentState,
  patientConsentReducer as patientConsent,
} from 'modules/patient-consent.module';
import {
  paymentFrequencyReducer as paymentFrequency,
  paymentFrequencySagas,
  PaymentFrequencyState,
} from 'modules/payment-frequency.module';
import {
  sessionReducer as sessions,
  sessionSagas,
  SessionsState,
} from 'modules/sessions.module';
import {
  singninAttemptReducer as signinAttempts,
  signinAttemptSagas,
  SigninAttemptsState,
} from 'modules/signin-attempts.module';
import {
  prescriptionReducer as prescriptions,
  prescriptionSagas,
  PrescriptionsState,
} from 'modules/prescriptions.module';
import {
  LicensedStatesReducer as licensedStates,
  LicensedStatesSagas,
  LicensedStatesState,
} from 'modules/licensed-states.module';
import {
  logsReducer as logs,
  logsSagas,
  LogsState,
} from './modules/logs.module';
import {
  categoryReducer as categories,
  categorySagas,
  CategoryState,
} from './modules/category.module';
import { createReduxHistoryContext, RouterState } from 'redux-first-history';
import {
  userInviteSagas,
  UserInvitationState,
  userInvitationReducer as userInvitation,
} from 'modules/user-invitation.module';
import {
  UsersState,
  usersSagas,
  usersReducer as users,
} from './modules/users.module';
import {
  AdminLogsState,
  adminLogsSagas,
  adminLogsReducer as adminLogs,
} from './modules/admin-logs.module';
import {
  UsersInvitesState,
  usersInvitesSagas,
  usersInvitesReducer as usersInvites,
} from './modules/users-invites.module';
import {
  timeslotsSagas,
  TimeslotsState,
  timeslotsReducer as timeslots,
} from './modules/timeslots.module';
import { MfaState, mfaReducer as mfa, mfaSagas } from './modules/mfa.module';
import {
  PublicBookingDurationsState,
  publicBookingDurationsSagas,
  publicBookingsDurationsReducer as publicBookingDuration,
} from './modules/public-booking-durations.module';
import {
  ImmunizationsState,
  immunizationSagas,
  immunizationsReducer as immunizations,
} from './modules/immunizations.module';
import {
  QuestionnairesState,
  questionnairesSaga,
  questionnairesReducer as questionnaires,
} from './modules/questionnaires.module';
import {
  ProviderLanguagesState,
  providerLanguagesSagas,
  providerLanguagesReducer as providerLanguages,
} from './modules/provider-languages.module';
import {
  ProviderCategoriesState,
  providerCategoriesSagas,
  providerCategoriesReducer as providerCategories,
} from 'modules/provider-categories.module';
import {
  profileTypesReducer,
  profileTypesSagas,
  ProfileTypesState,
} from 'modules/profile-types.module';

export interface MyState {
  auth: AuthState;
  forms: FormState;
  patients: PatientsState;
  router: RouterState;
  providers: ProvidersState;
  specialities: SpecialitiesState;
  credentials: CredentialsState;
  templates: TemplatesState;
  adminTools: AdminToolsState;
  notifications: NotificationsState;
  pharmacies: PharmaciesState;
  promoCodes: PromoCodesState;
  transactions: TransactionsState;
  medications: MedicationsState;
  affiliations: AffiliationsState;
  conditions: ConditionsState;
  allergies: AllergiesState;
  splitPercentage: SplitPercentageState;
  termsAndConditions: TermsAndConditionsState;
  privacyPolicies: PrivacyPoliciesState;
  paymentFrequency: PaymentFrequencyState;
  prescriptions: PrescriptionsState;
  sessions: SessionsState;
  signinAttempts: SigninAttemptsState;
  logs: LogsState;
  categories: CategoryState;
  licensedStates: LicensedStatesState;
  bookings: BookingsState;
  userInvitation: UserInvitationState;
  users: UsersState;
  adminLogs: AdminLogsState;
  usersInvites: UsersInvitesState;
  timeslots: TimeslotsState;
  mfa: MfaState;
  publicBookingDuration: PublicBookingDurationsState;
  patientConsent: PatientConsentState;
  organizations: OrganizationsState;
  bookingsPublic: BookingsPublicState;
  immunizations: ImmunizationsState;
  questionnaires: QuestionnairesState;
  organizationTypes: OrganizationTypesState;
  providerLanguages: ProviderLanguagesState;
  providerCategories: ProviderCategoriesState;
  profileTypes: ProfileTypesState;
}

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

const toastFactory = [
  ...ENTITIES.map((entity) => notificationFactory(entity).watcher),
];

const rootReducer = combineReducers({
  auth,
  forms,
  patients,
  providers,
  specialities,
  credentials,
  templates,
  adminTools,
  notifications,
  promoCodes,
  pharmacies,
  transactions,
  medications,
  affiliations,
  conditions,
  allergies,
  splitPercentage,
  termsAndConditions,
  privacyPolicies,
  paymentFrequency,
  prescriptions,
  sessions,
  signinAttempts,
  logs,
  categories,
  licensedStates,
  bookings,
  userInvitation,
  users,
  adminLogs,
  usersInvites,
  timeslots,
  mfa,
  publicBookingDuration,
  patientConsent,
  organizations,
  bookingsPublic,
  immunizations,
  questionnaires,
  organizationTypes,
  providerLanguages,
  providerCategories,
  profileTypes: profileTypesReducer,
  router: routerReducer,
});

const sagaMiddleware = createSagaMiddleware();
const enhancer = applyMiddleware(sagaMiddleware, routerMiddleware);

// @ts-ignore because of form reducer
const store = createStore(rootReducer, composeWithDevTools(enhancer));

// @ts-ignore because of form reducer
export const history = createReduxHistory(store);

function* rootSaga(): SagaIterator {
  yield all([
    ...forkList(mfaSagas),
    ...forkList(authSagas),
    ...forkList(formSagas),
    ...forkList(patientsSagas),
    ...forkList(providersSagas),
    ...forkList(specialitiesSagas),
    ...forkList(credentialsSagas),
    ...forkList(bookingsSagas),
    ...forkList(templatesSagas),
    ...forkList(adminToolsSagas),
    ...forkList(notificationsSagas),
    ...forkList(pharmacySagas),
    ...forkList(promoCodeSagas),
    ...forkList(transactionsSagas),
    ...forkList(medicationsSagas),
    ...forkList(affiliationSagas),
    ...forkList(conditionSagas),
    ...forkList(allergySagas),
    ...forkList(splitPercentageSagas),
    ...forkList(termAndConditionSagas),
    ...forkList(privacyPolicySagas),
    ...forkList(paymentFrequencySagas),
    ...forkList(sessionSagas),
    ...forkList(signinAttemptSagas),
    ...forkList(prescriptionSagas),
    ...forkList(logsSagas),
    ...forkList(toastFactory),
    ...forkList(childSagas),
    ...forkList(petSagas),
    ...forkList(LicensedStatesSagas),
    ...forkList(categorySagas),
    ...forkList(userInviteSagas),
    ...forkList(usersSagas),
    ...forkList(adminLogsSagas),
    ...forkList(usersInvitesSagas),
    ...forkList(timeslotsSagas),
    ...forkList(publicBookingDurationsSagas),
    ...forkList(patientConsentSagas),
    ...forkList(organizationsSagas),
    ...forkList(bookingsPublicSagas),
    ...forkList(immunizationSagas),
    ...forkList(questionnairesSaga),
    ...forkList(organizationTypesSagas),
    ...forkList(providerLanguagesSagas),
    ...forkList(providerCategoriesSagas),
    ...forkList(profileTypesSagas),
  ]);
}

sagaMiddleware.run(rootSaga);

export default store;

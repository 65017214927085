import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  ErrorAction,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { API_URL, AUTH_API_URL } from '../config';
import { Category } from '../typings';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { MyState } from 'store';
import { createSelector } from 'reselect';

interface CategoryIds {
  ids: number[];
}

interface ActionTypes {
  REQUEST_CATEGORIES: string;
  COMMIT_CATEGORIES: string;

  REQUEST_UPDATE_CREDENTIAL_CATEGORIES: string;
  COMMIT_UPDATE_CREDENTIAL_CATEGORIES: string;
}

interface ActionCreators {
  requestUpdateCredentialCategories: (
    payload: CategoryIds,
  ) => MyAction<CategoryIds>;
  commitUpdateCredentialCategories: () => MyAction<void>;
  loadingCategories: () => Action;
  requestCategories: () => MyAction<void>;
  commitCategories: (payload: Category[]) => MyAction<Category[]>;
  errorCategories: <TError extends Error>(error: TError) => ErrorAction<TError>;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestUpdateCredentialCategories: ['payload'],
  commitUpdateCredentialCategories: [],
  loadingCategories: [],
  requestCategories: [],
  errorCategories: ['error'],
  commitCategories: ['payload'],
});

const entityAdapter = createEntityAdapter<Category>();
export type CategoryState = EntityState<Category>;
const initialState = entityAdapter.getInitialState({});
export const categorySelectors = entityAdapter.getSelectors();

function commitUpdateProviderCategories(state: CategoryState): CategoryState {
  return {
    ...state,
  };
}

function commitCategories(
  state: CategoryState,
  action: MyAction<Category[]>,
): CategoryState {
  return {
    ...entityAdapter.addAll(action.payload, state),
  };
}

export const categoryReducer = createReducer(initialState, {
  [Types.COMMIT_UPDATE_CREDENTIAL_CATEGORIES]: commitUpdateProviderCategories,
  [Types.COMMIT_CATEGORIES]: commitCategories,
});

async function downloadCategories({
  headers,
}: ArgsWithHeaders<void>): Promise<Category[]> {
  const result = await fetch(`${API_URL}/credentials/categories`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }

  return (await result.json()).categories;
}

async function updateCredentialCategory({
  headers,
  ...payload
}: ArgsWithHeaders<CategoryIds>): Promise<any> {
  const result = await fetch(`${API_URL}/credentials/categories`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({ categories: payload.ids.map((id) => ({ id })) }),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error.msg);
  }
  return await result.json();
}

const requestUpdateCredentialCategoryWatcher = createSingleEventSaga<
  object,
  void,
  MyAction<CategoryIds>
>({
  takeEvery: Types.REQUEST_UPDATE_CREDENTIAL_CATEGORIES,
  loadingAction: Creators.loadingCategories,
  commitAction: Creators.commitUpdateCredentialCategories,
  successAction: noOpAction,
  errorAction: Creators.errorCategories,
  action: updateCredentialCategory,
  beforeAction: putAuthInfoInArgs,
});

const requestCategoryWatcher = createSingleEventSaga<
  object,
  Category[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_CATEGORIES,
  loadingAction: Creators.loadingCategories,
  commitAction: Creators.commitCategories,
  successAction: noOpAction,
  errorAction: Creators.errorCategories,
  action: downloadCategories,
  beforeAction: putAuthInfoInArgs,
});

export const categorySagas = [
  requestUpdateCredentialCategoryWatcher,
  requestCategoryWatcher,
];

export const selectCategoriesState = (state: MyState) => state.categories;

export const selectCategories = createSelector(
  selectCategoriesState,
  (categoriesSate) => categorySelectors.selectAll(categoriesSate),
);

import React, { Dispatch as ReactDispatch, FC, SetStateAction } from 'react';

import MMDModal from '../MMDModal';
import MMDButton from '../MMDButton';
import MMDFreeInput from '../MMDFreeInput';
import { ActionsProviderProps, ModalState } from './types';
import { REJECTED, USER_TYPE } from '../../utils/constants';
import { Creators as AdminToolsActions } from '../../modules/adminTools.module';
import { getCoordinatesString } from 'utils/getCoordinatesString';

type ActionsProviderModalProps = {
  loading: boolean;
  modalState: ModalState;
  setMotive: ReactDispatch<SetStateAction<string>>;
  motive: string;
} & Omit<ActionsProviderProps, 'requestStudentUnlink' | 'providerTeacher'> &
  Pick<typeof AdminToolsActions, 'requestNotifyUpdateProvider'>;

export const ActionsProviderModal: FC<ActionsProviderModalProps> = ({
  provider,
  modalState,
  loading,
  setModalState,
  setMotive,
  updateProviderHandler,
  requestNotifyUpdateProvider,
  motive,
}) => {
  if (!modalState.action) return null;
  const coordinates = getCoordinatesString(provider?.coordinates);
  const type = modalState.action.type;
  const visible = modalState.visible;

  const onSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (type === 'rejected') {
      updateProviderHandler(e, {
        approved: REJECTED,
        adminVerified: REJECTED,
        reason: motive,
        status: 'rejected',
        coordinates,
      });
    } else if (type === 'pending') {
      updateProviderHandler(e, {
        approved: REJECTED,
        adminVerified: REJECTED,
        reason: motive,
        status: 'pending',
        coordinates,
      });
    } else {
      requestNotifyUpdateProvider({
        id: provider.id,
        body: motive,
      });
    }

    setModalState({ visible: false, action: undefined });
    setMotive(undefined);
  };

  return (
    <MMDModal
      visible={visible && !loading}
      onRequestClose={() => {
        setModalState({ visible: false, action: undefined });
        setMotive(undefined);
      }}
    >
      {visible &&
        type === 'approved' &&
        modalState.action.to === USER_TYPE.PROVIDER && (
          <p>{`The ${modalState.action.to} was ${type} successfully`}</p>
        )}
      {((modalState.visible && type === 'rejected') ||
        (visible && type === 'notifiy') ||
        (visible && type === 'pending')) &&
        modalState.action.to === USER_TYPE.PROVIDER && (
          <div className="field">
            <MMDFreeInput
              label={
                type === 'rejected'
                  ? `Please, tell the ${
                      provider.teacherId ? 'student' : 'doctor'
                    } the reason for the denial`
                  : type === 'pending'
                    ? `Please, tell the ${
                        provider.teacherId ? 'student' : 'doctor'
                      } the reason to put him pending`
                    : 'Message to the provider'
              }
              onChange={(evt) => setMotive(evt.currentTarget.value)}
            />
            <MMDButton text="Send" isDanger onClick={onSend} />
          </div>
        )}
    </MMDModal>
  );
};
